import { useParams } from 'react-router';

import axios, { AxiosRequestConfig } from 'axios';

import { handleStorage } from './services/handleStorage';
import store from './store/store';
import RequestSocketActions from './utils/appDB/RequestSocketActions';
// import RequestHttpsActions from './utils/appDB/RequestHttpsActions';
import updateAuthToken from './utils/updateAuthToken';

// const requestHttpsActions = new RequestHttpsActions();
const requestSocketActions = new RequestSocketActions();

const config: AxiosRequestConfig = {
  baseURL:
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? 'https://api.pno.numdes.com'
      : 'https://develop.api.pno.numdes.com',
  headers: {
    'Content-Type': 'application/json',
  },
};

export const api = axios.create(config);

const handleRequest = (request: AxiosRequestConfig) => {
  const dispatch = store.dispatch;
  const socketState: any = store.getState().socket.socketState;
  if (socketState.isLoading || socketState.isConnect) {
    dispatch({ type: 'SOCKET_DISCONNECT' });
  }
  if (!request.headers?.common?.Authorization) {
    const token = handleStorage.getToken();
    if (!token) {
      return request;
    }
    updateAuthToken(token);
    request.headers.common.Authorization = `Bearer ${token}`;
  }
  return request;
};

const handleResponse = async (res: any) => {
  return res;
};

const handleError = async (error: any) => {
  if (error.response.status === 401) {
    handleStorage.removeToken();
    updateAuthToken(false);
    if (!window.location.href.includes('login')) {
      window.location.replace('#/login');
    }
  }

  return Promise.reject(error);
};

api.interceptors.request.use(handleRequest);

api.interceptors.response.use((res: any) => handleResponse(res), handleError);
